body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 4rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-grid{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.card{
  align-content:center;
  border: 1px solid grey;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 10px;
  margin-right: 10px;
}

.card p{
  padding: 5px;
  text-align:center;
}

.card img{
  width: 100%;
}

form input{
  width:100%;
  padding: 5px;
}